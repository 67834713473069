// Here you can add other styles

.border-bm-dotted {
  border: none;
  border-bottom: 2px solid black;
  border-bottom-style: dotted;
}

.sidebar {
  background-color: #494395;
}

.fs-small {
  font-size: 14px;
}
.fs-smaller {
  font-size: 12px;
}

.bg-dark {
  background: #494395 !important;
}

.btn-info {
  background: #2ab4aa;

  // &:hover {
  //   background: $danger;
  // }
}

.edit-button {
  border: 1px solid #2ab4aa !important;
  background-color: transparent !important;
  color: black;
  font-size: small;
  box-shadow: none !important;
  &:hover {
    background-color: #2ab4aa !important;
    color: white;
  }
}

.react-confirm-alert-button-group :nth-child(1) {
  background: $success !important;
}

.react-confirm-alert-button-group :nth-child(2) {
  background: $danger !important;
}

.login-page-bg {
  background-image: url('../assets/images/background.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.btn-group {
  width: 35px;
  height: 35px;
}
